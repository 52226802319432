var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}]},[(_vm.noSlides)?_c('div',{staticClass:"w-full h-[64vw] max-h-[652px] lg:h-[20.8vw] lg:max-h-[531px]"},[_c('SkeletonLoader',{attrs:{"width":"100%","height":"100%"}})],1):_vm._e(),_vm._v(" "),(_vm.isSingleSlide)?_c('div',{staticClass:"mx-auto relative mb-5 md:mb-12",on:{"click":function($event){return _vm.pushToUrl(_vm.blok.slide[0], 1)}}},[_c('div',{staticClass:"w-full bg-cover bg-center cursor-pointer block"},[_c('StoryblokComponent',{attrs:{"blok":_vm.blok.slide[0].banner[0],"lazy-load":false}})],1)]):_c('div',{staticClass:"mx-auto relative mb-5 md:mb-24"},[_c('div',{staticClass:"slider-cls-wrapper"},[_c('div',{staticClass:"slider-cls-wrapper__slider"},[_c('SfCarousel',{ref:"carouselRef",staticClass:"w-full",attrs:{"arrows":true,"dots":false,"mobile-dots-pc-arrows":true,"settings":Object.assign({}, {type: 'slider',
            rewind: true,
            perView: 1,
            slidePerPage: true,
            gap: 10},
            (_vm.blok.swiperAutoplay && { autoplay: 2000 }))}},_vm._l((_vm.blok.slide.slice(0, 5)),function(childrenBlok,index){return _c('SfCarouselItem',{key:childrenBlok._uid + 'slide'},[_c('div',{staticClass:"block w-full bg-cover bg-center cursor-pointer",on:{"click":function($event){return _vm.pushToUrl(childrenBlok, index+1)}}},_vm._l((childrenBlok.banner),function(childrenBlokBanner){return _c('div',{key:childrenBlokBanner._uid + 'mainSlider-banner',staticClass:"w-full block"},[_c('StoryblokComponent',{attrs:{"blok":childrenBlokBanner,"lazy-load":index > 0}})],1)}),0)])}),1)],1)]),_vm._v(" "),_c('div',[_c('button',{staticClass:"cursor-pointer px-6 py-1.5 text-base text-center rounded-full my-3 block mx-auto lg:hidden",attrs:{"type":"button"},on:{"click":function($event){_vm.isModalOpen = true}}},[_vm._v("\n        Pokaż wszystkie\n        "+_vm._s(_vm.blok.slide.length > 5 ? ("(" + (_vm.blok.slide.length) + ")") : '')+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"w-full lg:flex bg-white rounded-full max-w-fit justify-center mx-auto left-0 right-0 absolute nav-item-wrapper hidden"},[_vm._l((_vm.blok.slide.slice(0, 5)),function(childrenBlok,index){return _c('div',{key:childrenBlok._uid + 'nav'},_vm._l((childrenBlok.banner),function(childrenBlokBanner){return _c('button',{key:childrenBlokBanner._uid + 'nav-item',staticClass:"text-sm text-center",class:[
            'cursor-pointer border px-3 py-1 rounded-full mr-1 duration-300',
            index === _vm.activeIndex ? 'bg-primary border-primary text-white' : 'border-white bg-white text-black' ],attrs:{"type":"button"},on:{"click":function($event){return _vm.goToSpecificSlide(index)}}},[_vm._v("\n          "+_vm._s(childrenBlokBanner.Alt || childrenBlokBanner.title)+"\n        ")])}),0)}),_vm._v(" "),_c('button',{staticClass:"cursor-pointer px-3 py-1 text-sm text-center rounded-full",attrs:{"type":"button"},on:{"click":function($event){_vm.isModalOpen = true}}},[_vm._v("\n        Pokaż wszystkie\n        "+_vm._s(_vm.blok.slide.length > 5 ? ("(" + (_vm.blok.slide.length) + ")") : '')+"\n      ")])],2),_vm._v(" "),_c('Modal',{attrs:{"title":"Promocje","is-open":_vm.isModalOpen,"on-close":_vm.closeModal}},[_c('div',{staticClass:"content-wrapper"},_vm._l((_vm.blok.slide),function(childrenBlok,index){return _c('div',{key:childrenBlok._uid + 'sf-modal-slide',staticClass:"sf-modal-slide"},[_c('div',{staticClass:"block w-full bg-cover bg-center cursor-pointer",on:{"click":function($event){return _vm.pushToUrl(childrenBlok, index + 1)}}},_vm._l((childrenBlok.banner),function(childrenBlokBanner){return _c('div',{key:childrenBlokBanner._uid + 'modal-banner',staticClass:"w-full block"},[_c('StoryblokComponent',{attrs:{"blok":childrenBlokBanner}})],1)}),0)])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }